import React, { useState } from "react";
import { Button, Drawer, Input, Form, Modal, Space, Typography } from "antd";
import { EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import { DataFields } from "../../pages/client-billing/data-fields";
import SelectInvoiceStatus from "../invoices-status/select-invoice-status";
import { useAPI } from "../../client-api/utils/api";
import { InvoicesNotesDataApi } from "../../client-api";

const { confirm } = Modal;

type Props = {
  filters: any;
  iconMode?: boolean;
  onConfirm?: (result: any) => void;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ClientBillingEdit: React.FC<Props> = (props) => {
  const { filters, iconMode = false, onConfirm } = props;
  const api = useAPI(InvoicesNotesDataApi);

  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const removeEmptyProperties = (obj: { [s: string]: any }) => {
    return Object.entries(obj).reduce((acc: any, [key, value]) => {
      if (!!value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const showConfirm = (dataToUpdate: any) => {
    const contentData = Object.entries(dataToUpdate).map(([key, value]) => {
      return (
        <span>
          {DataFields[key]}:<b>{value as string}</b>
        </span>
      );
    });
    const content = (
      <Space direction="vertical" size={0}>
        Deseja atulizar os dados:
        {contentData}
        <Typography.Text type="danger" strong>
          X registros serão atualizados
        </Typography.Text>
      </Space>
    );
    confirm({
      title: "Confirma atualização dos dados?",
      icon: <ExclamationCircleFilled />,
      content: content,
      onOk() {
        return api
          .invoicesNotesDataControllerUpdate({
            ...filters,
            updateInvoicesNotesDataDto: dataToUpdate,
          })
          .then((data: any) => {
            onConfirm?.(data);
            setOpen(false);
          });
      },
      onCancel() {},
    });
  };

  const onFinish = (values: any) => {
    showConfirm(removeEmptyProperties(values));
  };

  return (
    <>
      <Button
        ghost
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={showDrawer}
        title="Editar"
      >
        {!iconMode && "Editar dados"}
      </Button>
      {open && (
        <Drawer title="Editar dados" onClose={onClose} open={open} size="large">
          <Form
            variant={"filled"}
            onFinish={onFinish}
            {...formItemLayout}
            style={{ maxWidth: 600 }}
            initialValues={{}}
          >
            {Object.keys(DataFields).map((field: string) => (
              <Form.Item name={field} label={DataFields[field] as string}>
                {field !== "status" ? <Input /> : <SelectInvoiceStatus />}
              </Form.Item>
            ))}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
    </>
  );
};

export default ClientBillingEdit;
