import HomePage from "./pages/home";
import SettingsPage from "./pages/settings";
import ImportPage from "./pages/import";
import ClientBilling from "./pages/client-billing";
import RoyaltiesNotes from "./pages/royalties-notes";
import QuatasList from "./pages/quotas";

const menu = [
  {
    path: "/",
    name: "Início",
    component: HomePage,
  },
  {
    path: "/reports",
    name: "Relatorio",
    routes: [
      {
        path: "clientbilling",
        name: "Faturamento",
        component: ClientBilling,
      },
      {
        path: "royaltiesnotes",
        name: "Relatório ITS ",
        component: RoyaltiesNotes,
      },
      {
        path: "itsquotas",
        name: "Quotas",
        component: QuatasList,
      },
    ],
  },
  {
    path: "/datasource",
    name: "Fonte de dados",
    routes: [
      {
        path: "file",
        name: "Arquivos xls",
        component: ImportPage,
      },
    ],
  },
  {
    path: "/setting",
    name: "Configurações",
    component: SettingsPage,
  },
];

export default menu;
