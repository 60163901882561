// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LayoutBase from "./layout-base";
import MenuData from "./menu";
import LoginPage from "./pages/login";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import axios from "axios";
import createRefresh from "react-auth-kit/createRefresh";

import { API_URL } from "./client-api/utils/api";

const refresh = createRefresh({
  interval: 5,
  refreshApiCallback: async (param): Promise<any> => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/refresh`,
        { refresh_token: param.refreshToken },
        {
          headers: { Authorization: `Bearer ${param.authToken}` },
        }
      );
      return {
        isSuccess: true,
        newAuthToken: response.data.token,
        newAuthTokenExpireIn: 5,
        newRefreshTokenExpiresIn: 60,
      };
    } catch (error) {
      return {
        isSuccess: false,
      };
    }
  },
});

const store = createStore({
  authName: "_auth",
  authType: "localstorage",
  // cookieDomain: window.location.hostname,
  // cookieSecure: window.location.protocol === "https:",
  refresh: refresh,
});

const addRoutes = (routes: any[]) =>
  routes.map((route, index) =>
    route.routes ? (
      <Route key={index} path={route.path}>
        {addRoutes(route.routes)}
      </Route>
    ) : (
      <Route key={index} path={route.path} element={<route.component />} />
    )
  );

function App() {
  return (
    <AuthProvider store={store}>
      <Router>
        <Routes>
          <Route key={"loginpage"} path={"/login"} element={<LoginPage />} />
          <Route path="/" element={<LayoutBase />}>
            <Route>{addRoutes(MenuData)}</Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
