import {
  ActionType,
  EditableProTable,
  ProTable,
} from "@ant-design/pro-components";
import { Divider } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  InvoicesNotesDataApi,
  InvoicesNotesDataControllerFindAllRequest,
  RoyaltiesNotesDataApi,
  RoyaltiesNotesDataControllerFindAllRequest,
} from "../../client-api";
import { useAPI } from "../../client-api/utils/api";
// import { clientBillingColumns } from "../../pages/client-billing";
import { royaltiesNotesDataColumns } from "../../pages/royalties-notes";
import EditDataFields from "../../pages/client-billing/data-fields";
import TagInvoice from "../invoices-status/tag-invoice";

type InvoicesRecordsProps = {
  keyIntegration: string;
};

const InvoicesRecords: React.FC<InvoicesRecordsProps> = (props) => {
  const { keyIntegration } = props;
  const actionRefInvoicesNotes = useRef<ActionType>();
  const actionRefRoyaltiesNotes = useRef<ActionType>();
  const invoicesNotesApi = useAPI(InvoicesNotesDataApi);
  const royaltiesNotesApi = useAPI(RoyaltiesNotesDataApi);

  useEffect(() => {
    actionRefInvoicesNotes?.current?.reload();
    actionRefRoyaltiesNotes?.current?.reload();
  }, [keyIntegration]);

  const clientBillingColumns = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: EditDataFields["status"],
      dataIndex: "status",
      width: 100,
      renderText: (value: string) => (
        <>
          <TagInvoice value={value} />
        </>
      ),
    },
    {
      title: EditDataFields["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataNfe"],
      dataIndex: "editDataNfe",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataBatch"],
      dataIndex: "editDataBatch",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      ellipsis: true,
      width: 100,
    },
    {
      title: EditDataFields["editDataUf"],
      dataIndex: "editDataUf",
      ellipsis: true,
      width: 30,
    },
    {
      title: EditDataFields["editDataCity"],
      dataIndex: "editDataCity",
      ellipsis: true,
      width: 90,
    },
    {
      title: EditDataFields["editDataBillingDate"],
      dataIndex: "editDataBillingDate",
      ellipsis: true,
      width: 90,
    },
    {
      title: EditDataFields["editDataSeedUse"],
      dataIndex: "editDataSeedUse",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataCultivate"],
      dataIndex: "editDataCultivate",
      ellipsis: true,
      width: 40,
    },
    {
      title: EditDataFields["editDataQtyPackaging"],
      dataIndex: "editDataQtyPackaging",
      ellipsis: true,
      width: 40,
    },
    {
      title: EditDataFields["editDataClientName"],
      dataIndex: "editDataClientName",
      ellipsis: true,
      width: 200,
    },
    {
      title: EditDataFields["editDataTechnology"],
      dataIndex: "editDataTechnology",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataWeightPackaging"],
      dataIndex: "editDataWeightPackaging",
      ellipsis: true,
      width: 60,
    },
    {
      title: EditDataFields["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      ellipsis: true,
      width: 40,
    },
  ];

  return (
    <>
      <ProTable
        scroll={{
          x: true,
        }}
        actionRef={actionRefInvoicesNotes}
        columns={clientBillingColumns}
        request={(params) => {
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as InvoicesNotesDataControllerFindAllRequest;
          query.keyIntegration = keyIntegration;
          return invoicesNotesApi
            .invoicesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            });
        }}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="id"
        toolbar={{ title: "Notas faturamento" }}
        search={false}
      />
      <Divider />
      <ProTable
        scroll={{
          x: true,
        }}
        actionRef={actionRefRoyaltiesNotes}
        columns={royaltiesNotesDataColumns}
        request={(params) => {
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as RoyaltiesNotesDataControllerFindAllRequest;
          query.keyIntegration = keyIntegration;
          return royaltiesNotesApi
            .royaltiesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            });
        }}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="id"
        toolbar={{ title: "Notas ITS" }}
        search={false}
      />
    </>
  );
};

export default InvoicesRecords;
