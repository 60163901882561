import React from "react";
import axios from "axios";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProConfigProvider,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router-dom";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { theme } from "antd";
import { API_URL } from "../../client-api/utils/api";

const LoginPage: React.FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const signIn = useSignIn();

  const onSubmit = (values: { email: string; pass: string }) => {
    axios.post(`${API_URL}/auth/login`, values).then((res) => {
      if (res.status === 200 || res.status === 201) {
        const authData = signIn({
          auth: {
            token: res.data.access_token,
            type: "Bearer",
          },
          refresh: res.data.refresh_token,
          // userState: res.data.authUserState
        });
        if (authData) {
          navigate("/");
          // Only if you are using refreshToken feature
          // Redirect or do-something
        } else {
          navigate("/login");
          //Throw error
        }
      }
    });
  };

  return (
    <ProConfigProvider hashed={false}>
      <div style={{ backgroundColor: token.colorBgContainer }}>
        <LoginForm
          logo={process.env.PUBLIC_URL + "logo.png"}
          title="AgroRoyalties"
          subTitle="souagrosoluções"
          onFinish={(values: { email: string; pass: string }) =>
            onSubmit(values)
          }
          submitter={{
            searchConfig: {
              submitText: "Entra",
            },
          }}
        >
          <ProFormText
            name="email"
            fieldProps={{
              size: "large",
              prefix: <UserOutlined className={"prefixIcon"} />,
            }}
            placeholder={"nome@email.com.br"}
            rules={[
              {
                required: true,
                message: "Por favor insira o email do usuário!",
              },
            ]}
          />
          <ProFormText.Password
            name="pass"
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
              strengthText:
                "A senha deve conter números, letras e caracteres especiais, com pelo menos 8 caracteres.",
            }}
            placeholder={"senha"}
            rules={[
              {
                required: true,
                message: "Por favor digite sua senha!",
              },
            ]}
          />

          <div
            style={{
              marginBlockEnd: 24,
            }}
          >
            <ProFormCheckbox noStyle name="autoLogin">
              Login automático
            </ProFormCheckbox>
            <a
              style={{
                float: "right",
              }}
            >
              esqueçeu a senha
            </a>
          </div>
        </LoginForm>
      </div>
    </ProConfigProvider>
  );
};

export default LoginPage;
