/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginationResponseDto,
  StatusSummaryDto,
} from '../models/index';
import {
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
    StatusSummaryDtoFromJSON,
    StatusSummaryDtoToJSON,
} from '../models/index';

export interface ReportsControllerExportITSNotesRequest {
    xOwnerId?: string;
}

export interface ReportsControllerGetNotesRequest {
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
}

export interface ReportsControllerGetStatusRequest {
    xOwnerId?: string;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     */
    async reportsControllerExportITSNotesRaw(requestParameters: ReportsControllerExportITSNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/reports/its/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async reportsControllerExportITSNotes(requestParameters: ReportsControllerExportITSNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.reportsControllerExportITSNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsControllerGetNotesRaw(requestParameters: ReportsControllerGetNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/reports/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportsControllerGetNotes(requestParameters: ReportsControllerGetNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.reportsControllerGetNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportsControllerGetStatusRaw(requestParameters: ReportsControllerGetStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StatusSummaryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/reports/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatusSummaryDtoFromJSON));
    }

    /**
     */
    async reportsControllerGetStatus(requestParameters: ReportsControllerGetStatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StatusSummaryDto>> {
        const response = await this.reportsControllerGetStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
