import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Flex, Modal, Radio, Space, Typography } from "antd";
import { ShopOutlined } from "@ant-design/icons";

import { useAPI } from "../../client-api/utils/api";
import { OwnerApi, OwnerDTOToJSON } from "../../client-api";
import { useGlobalApp } from "../global-app-provider";
import { Owner } from "../global-app-provider/provider";

interface Props {
  onSwitcher: () => void;
}

const SwitchClient = (props: Props) => {
  const { onSwitcher } = props;
  const api = useAPI(OwnerApi);
  const { setOwner, getOwner } = useGlobalApp();
  const currentOwner = getOwner();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<{ id: string; name: string }[]>([]);
  const [selectOwner, setSelectOwner] = useState<Owner | false>(false);

  const handleOk = () => {
    setOwner(selectOwner);
    setIsModalOpen(false);
    onSwitcher();
  };

  const fetchOwner = () => {
    api.ownerControllerFindAll().then((data) => {
      const dataObj = data.map((d) => OwnerDTOToJSON(d));
      setData(dataObj);
    });
  };

  useEffect(() => {
    fetchOwner();
  }, []);

  const showModal: boolean = !currentOwner || isModalOpen;

  return (
    <>
      {showModal && (
        <Modal
          destroyOnClose
          title="Selecione um cliente"
          open={showModal}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{
            disabled: !currentOwner,
          }}
          onCancel={() => setIsModalOpen(false)}
        >
          <div style={{ padding: "10px" }}>
            <Radio.Group
              onChange={(e) => setSelectOwner(e.target.value)}
              buttonStyle="solid"
            >
              <Flex
                gap="middle"
                align="center"
                justify="space-between"
                wrap="wrap"
              >
                {data.map((c) => (
                  <Radio value={c} key={c.id}>
                    <Space direction="vertical" align="center">
                      <Avatar
                        style={{ backgroundColor: "#87d068" }}
                        icon={<ShopOutlined />}
                      />
                      {c.name}
                    </Space>
                  </Radio>
                ))}
              </Flex>
            </Radio.Group>
          </div>
        </Modal>
      )}
      <Dropdown
        menu={{
          items: [
            {
              key: "showOwnerList",
              label: "alterar",
              onClick: () => {
                setIsModalOpen(true);
              },
            },
          ],
        }}
      >
        <Typography.Text>
          {!!currentOwner ? (
            <>
              <ShopOutlined /> {currentOwner?.name}
            </>
          ) : (
            "selecione uma empresa"
          )}
        </Typography.Text>
      </Dropdown>
    </>
  );
};

export default SwitchClient;
