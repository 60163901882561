import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import MenuData from "./menu";
import { ProLayout } from "@ant-design/pro-components";
import { Button, ConfigProvider, Dropdown, theme } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

import ptBR from "antd/locale/pt_BR";
import SwitchClient from "./components/switch-client";
import { GlobalAppProvider } from "./components/global-app-provider";
// import { Locale } from "antd/es/locale";

const LayoutBase: React.FC = () => {
  // const [locale] = useState<Locale>(ptBR);
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const [refreshKey, setRefreshKey] = useState(0);

  const navigate = useNavigate();
  const signOut = useSignOut();
  const [pathname, setPathname] = useState(location.pathname);

  const actionRef = useRef<{
    reload: () => void;
  }>();

  useEffect(() => {
    if (!isAuthenticated) {
      // Redireciona para a página de login se não estiver autenticado
      signOut(); // Remove o token atual
      navigate("/login"); // Redireciona para a página de login
    }
  }, [isAuthenticated, navigate, signOut]);

  const switchClientRefresh = () => {
    navigate("/");
    setRefreshKey((prevKey) => prevKey + 1);
  };

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <GlobalAppProvider>
      <ConfigProvider
        locale={ptBR}
        theme={{
          // 1. Use dark algorithm
          algorithm: theme.compactAlgorithm,
          // 2. Combine dark algorithm and compact algorithm
          //algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        }}
      >
        <ProLayout
          style={{
            height: "100vh",
          }}
          title="AgroRoyalties"
          logo={process.env.PUBLIC_URL + "logo.png"}
          actionRef={actionRef}
          suppressSiderWhenMenuEmpty={false}
          menu={{
            request: async () => {
              return MenuData;
            },
          }}
          layout="mix"
          colorWeak={false}
          actionsRender={(props) => {
            if (props.isMobile) return [];
            if (typeof window === "undefined") return [];
            return [
              <SwitchClient onSwitcher={() => switchClientRefresh()} />,
              <Button
                icon={<AlertOutlined />}
                title="notificações"
                size="large"
                type="text"
              />,
              // <InfoCircleFilled key="InfoCircleFilled" />,
              // <QuestionCircleFilled key="QuestionCircleFilled" />,
              // <GithubFilled key="GithubFilled" />,
            ];
          }}
          avatarProps={{
            src: "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
            size: "small",
            title: "Usuário",
            render: (props, dom) => {
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "logout",
                        label: "Sair",
                        onClick: () => {
                          signOut();
                          navigate("/login");
                        },
                      },
                    ],
                  }}
                >
                  {dom}
                </Dropdown>
              );
            },
          }}
          menuItemRender={(menuItemProps, defaultDom) => {
            if (
              menuItemProps.isUrl ||
              menuItemProps.children ||
              !menuItemProps.path
            ) {
              return defaultDom;
            }
            return (
              <Link
                onClick={() => setPathname(menuItemProps.path || "/")}
                to={menuItemProps.path}
              >
                {defaultDom}
              </Link>
            );
          }}
          location={{
            pathname,
          }}
        >
          <Outlet key={refreshKey} />
        </ProLayout>
      </ConfigProvider>
    </GlobalAppProvider>
  );
};

export default LayoutBase;
