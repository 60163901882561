// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { Badge, Button, Input, Select, Space, Tag, Typography } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { InvoicesNotesDataApi } from "../../client-api";
import TagInvoice from "../../components/invoices-status/tag-invoice";
import { InvoiceDef } from "../../components/invoices-status/types";
import {
  ExclamationCircleOutlined,
  WarningOutlined,
  ClearOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import labels from "./data-fields";
import ClientBillingEdit from "../../components/client-billing-edit/indes";

const ClientBilling: React.FC = () => {
  const actionRef: any = createRef<ActionType>();
  const api = useAPI(InvoicesNotesDataApi);

  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("all");
  const [filteredInfo, setFilteredInfo] = useState<{ [key: string]: any }>({});

  const renderBadge = (active = false) => {
    return (
      <Badge
        count={<ExclamationCircleOutlined />}
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
          color: active ? "#1890FF" : "#f5222d",
          // backgroundColor: active ? "#E6F7FF" : "#eee",
        }}
      />
    );
  };

  const defaultFilter = (dataIndex: string) => {
    return {
      filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters, close } =
          props;
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Space.Compact block size="small">
              <Input
                placeholder={`Search`}
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
              />
              <Button
                type="primary"
                size="middle"
                title="Filtrar"
                icon={<CheckOutlined />}
                onClick={() => {
                  close();
                  // const newValue: { [key: string]: any } = {};
                  // newValue[dataIndex] = selectedKeys[0];
                  // filteredInfo[dataIndex] = selectedKeys[0];
                  // console.log(newValue, filteredInfo);
                  // setFilteredInfo({ ...newValue });
                  confirm({ closeDropdown: true });
                }}
              />
              <Button
                type="dashed"
                size="middle"
                title="Limpar filtro"
                icon={<ClearOutlined />}
                onClick={() => {
                  clearFilters?.();
                  confirm({ closeDropdown: true });
                }}
              />
            </Space.Compact>
          </div>
        );
      },
      onFilter: (value: any, record: any) => true,
      filteredValue: dataIndex ? filteredInfo?.[dataIndex] : null,
    };
  };

  const renderText = (editData: string, data: string) => {
    if (data !== editData) {
      return (
        <Space direction="vertical" size={0}>
          <Typography.Text>{editData}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            {data}
          </Typography.Text>
        </Space>
      );
    }
    return editData;
  };

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: labels["status"],
      dataIndex: "status",
      width: 100,
      filterSearch: true,
      filterMultiple: false,
      filteredValue: filteredInfo?.status || null,
      onFilter: (value, record) => true,
      filters: Object.keys(InvoiceDef).map((el) => ({
        value: el,
        text: InvoiceDef[el].label,
      })),
      renderText: (value: string, record) => (
        <>
          <TagInvoice value={value} />
          {record.revision && <WarningOutlined />}
        </>
      ),
      renderFormItem: (__, props) => (
        <Select
          {...props}
          options={Object.keys(InvoiceDef).map((el) => ({
            value: el,
            label: InvoiceDef[el].label,
          }))}
        />
      ),
    },
    {
      title: labels["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      ellipsis: true,
      width: 200,
      ...defaultFilter("editDataMultiplierIdentifierNumber"),
      renderText: (text, record) =>
        renderText(text, record.dataMultiplierIdentifierNumber),
    },
    {
      title: labels["editDataNfe"],
      dataIndex: "editDataNfe",
      ellipsis: true,
      // width: 100,
      ...defaultFilter("editDataNfe"),
      renderText: (text, record) => renderText(text, record.dataNfe),
    },
    {
      title: labels["editDataBatch"],
      dataIndex: "editDataBatch",
      ellipsis: true,
      // width: 100,
      ...defaultFilter("editDataBatch"),
      renderText: (text, record) => renderText(text, record.dataBatch),
    },
    {
      title: labels["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      ellipsis: true,
      width: 200,
      ...defaultFilter("editDataIdentifierNumber"),
      renderText: (text, record) =>
        renderText(text, record.dataIdentifierNumber),
    },
    {
      title: labels["editDataUf"],
      dataIndex: "editDataUf",
      ellipsis: true,
      // width: 30,
      ...defaultFilter("editDataUf"),
      renderText: (text, record) => renderText(text, record.dataUf),
    },
    {
      title: labels["editDataCity"],
      dataIndex: "editDataCity",
      ellipsis: true,
      width: 150,
      ...defaultFilter("editDataCity"),
      renderText: (text, record) => renderText(text, record.dataCity),
    },
    {
      title: labels["editDataBillingDate"],
      dataIndex: "editDataBillingDate",
      ellipsis: true,
      // width: 90,
      ...defaultFilter("editDataBillingDate"),
      renderText: (text, record) => renderText(text, record.dataBillingDate),
    },
    {
      title: labels["editDataSeedUse"],
      dataIndex: "editDataSeedUse",
      ellipsis: true,
      // width: 60,
      ...defaultFilter("editDataSeedUse"),
      renderText: (text, record) => renderText(text, record.dataSeedUse),
    },
    {
      title: labels["editDataCultivate"],
      dataIndex: "editDataCultivate",
      ellipsis: true,
      // width: 40,
      ...defaultFilter("editDataCultivate"),
      renderText: (text, record) => renderText(text, record.dataCultivate),
    },
    {
      title: labels["editDataQtyPackaging"],
      dataIndex: "editDataQtyPackaging",
      ellipsis: true,
      // width: 40,
      ...defaultFilter("editDataQtyPackaging"),
      renderText: (text, record) => renderText(text, record.dataQtyPackaging),
    },
    {
      title: labels["editDataClientName"],
      dataIndex: "editDataClientName",
      ellipsis: true,
      width: 200,
      ...defaultFilter("editDataClientName"),
      renderText: (text, record) => renderText(text, record.dataClientName),
    },
    {
      title: labels["editDataTechnology"],
      dataIndex: "editDataTechnology",
      ellipsis: true,
      // width: 60,
      ...defaultFilter("editDataTechnology"),
      renderText: (text, record) => renderText(text, record.dataTechnology),
    },
    {
      title: labels["editDataWeightPackaging"],
      dataIndex: "editDataWeightPackaging",
      ellipsis: true,
      // width: 60,
      ...defaultFilter("editDataWeightPackaging"),
      renderText: (text, record) =>
        renderText(text, record.dataWeightPackaging),
    },
    {
      title: labels["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      ellipsis: true,
      // width: 40,
      ...defaultFilter("editDataWeightKq"),
      renderText: (text, record) => renderText(text, record.dataWeightKq),
    },
  ];

  const renderFilteredInfo = () => {
    const tags = Object.keys(filteredInfo)
      .filter((el) => !!filteredInfo?.[el])
      .map((el: string) => {
        return (
          <Tag
            closable={false}
            onClose={(e) => {
              e.preventDefault();
              // setFilteredInfo({ ...filteredInfo, [el]: null });
              // actionRef?.current?.reload();
            }}
          >
            {labels[el as keyof typeof labels] || ""}
            {": "}
            {filteredInfo[el]?.toString()}
          </Tag>
        );
      });
    if (tags.length > 0) {
      tags.push(
        <ClientBillingEdit
          filters={filteredInfo}
          onConfirm={() => actionRef?.current?.reload()}
        />
      );
    }
    return tags;
  };

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: 2500 }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={clientBillingColumns}
        actionRef={actionRef}
        // params={filteredInfo}
        request={(params, sorter, filter) => {
          setLoading(true);
          const filterInvoices =
            activeKey === "inrevision"
              ? { ...filter, ...params, revision: true }
              : { ...filter, ...params };
          delete filterInvoices.current;
          delete filterInvoices.pageSize;
          setFilteredInfo(filterInvoices);
          const getItems =
            activeKey !== "withoutquota"
              ? api.invoicesNotesDataControllerFindAll({
                  page: params?.current,
                  pageSize: params?.pageSize,
                  ...filterInvoices,
                })
              : api.invoicesNotesDataControllerFindAllWithoutQuotas({
                  page: params?.current,
                  pageSize: params?.pageSize,
                  ...filterInvoices,
                });
          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        toolbar={{
          menu: {
            type: "tab",
            activeKey: activeKey,
            items: [
              {
                key: "all",
                label: <span>Todos</span>,
              },
              {
                key: "inrevision",
                label: (
                  <span>Revisão{renderBadge(activeKey === "inrevision")}</span>
                ),
              },
              {
                key: "withoutquota",
                label: (
                  <span>
                    Sem cadastro de cotas
                    {renderBadge(activeKey === "withoutquota")}
                  </span>
                ),
              },
            ],
            onChange: (key) => {
              setActiveKey(key as string);
              actionRef?.current?.reload();
            },
          },
          actions: renderFilteredInfo(),
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 20 }}
        search={false}
        dateFormatter="string"
      />
    </PageContainer>
  );
};

export default ClientBilling;
