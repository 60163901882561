import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Configuration } from "..";
import { useGlobalApp } from "../../components/global-app-provider";

export const API_BASE_PATH = process.env.API_URL
  ? process.env.API_BASE_PATH
  : "http://app.agroroyalties.com.br:3001"; // URL base da sua API

export const API_URL = process.env.API_URL
  ? process.env.API_URL
  : `${API_BASE_PATH}/api/v1`; // URL base da sua API

export const getEndpoint = (method: string) => `${API_URL}${method}`;

export const useAPIConfiguration = (OwnerId: string) => {
  const authHeader = useAuthHeader(); // Hook do react-auth-kit para obter o cabeçalho de autenticação
  const token = authHeader; // Obtém o token do cabeçalho de autenticação

  const configuration = new Configuration({
    basePath: API_BASE_PATH,
    headers: {
      Authorization: token || "notoken",
      "Content-Type": "application/json",
      "x-owner-id": OwnerId,
    },
  });

  return configuration;
};

type Constructor<T> = new (...args: any[]) => T;

export const useAPI = <T>(ClassAPI: Constructor<T>, ...args: any[]): T => {
  const { getOwner } = useGlobalApp();
  const currentOwner = getOwner();

  const config = useAPIConfiguration(
    currentOwner !== false ? currentOwner.id : "-1"
  );
  const finalArgs = args.length > 0 ? args : [config];
  return new ClassAPI(...finalArgs);
};
