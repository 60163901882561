import { StatisticCard } from "@ant-design/pro-components";
import { Card, Col, Flex, Progress, Row } from "antd";
import { useEffect, useState } from "react";
import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi, StatusSummaryDto } from "../../../client-api";

const Summary = () => {
  const api = useAPI(ReportsApi);
  const [loading, setLoading] = useState<boolean>(false);
  const [sumarryMain, setSumarryMain] = useState<StatusSummaryDto[]>([]);
  const [sumarrySecondary, setSumarrySecondary] = useState<StatusSummaryDto[]>(
    []
  );

  useEffect(() => {
    api
      .reportsControllerGetStatus()
      .then(async (data) => {
        const main: StatusSummaryDto[] = [];
        const secondary: StatusSummaryDto[] = [];
        data.forEach((item) => {
          if (item.tryMatch) main.push(item);
          else secondary.push(item);
        });
        setSumarryMain(main);
        setSumarrySecondary(secondary);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Card
      hoverable={false}
      bordered={false}
      loading={loading}
      title="Registro por status"
    >
      <Flex wrap gap="middle" vertical>
        <Flex wrap gap="middle" vertical={false}>
          {sumarryMain?.map((sumarry) => {
            return (
              <Flex align="center" gap="small">
                <StatisticCard
                  statistic={{
                    title: sumarry.name,
                    value: `${sumarry.count || 0}/${sumarry.total || 0}`,
                  }}
                  chart={
                    <Progress
                      type="dashboard"
                      status="normal"
                      percent={
                        !!sumarry?.count
                          ? Math.floor((sumarry?.count / sumarry?.total) * 100)
                          : 0
                      }
                      size={80}
                      strokeColor={sumarry.color}
                      strokeWidth={10}
                    />
                  }
                  chartPlacement="left"
                />
              </Flex>
            );
          })}
        </Flex>

        <Row gutter={4}>
          {sumarrySecondary?.map((sumarry) => {
            const percent = !!sumarry?.count
              ? (sumarry?.count / sumarry?.total) * 100
              : 0;
            return (
              <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                <Progress
                  type="circle"
                  percent={percent}
                  strokeWidth={20}
                  strokeColor={sumarry.color}
                  size={20}
                  format={(number) => `${number}%`}
                />
                <span>
                  ({sumarry.count || 0}/{sumarry.total || 0}) {sumarry.name}
                </span>
              </Col>
            );
          })}
        </Row>
      </Flex>
    </Card>
  );
};

export default Summary;
