import type { ProColumns } from "@ant-design/pro-components";
import { ProCard, ProTable } from "@ant-design/pro-components";
import { CloudDownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi } from "../../../client-api";
import InvoicesRecords from "../../../components/invoices-records/indes";
import { Button, Empty } from "antd";
import TagInvoice from "../../../components/invoices-status/tag-invoice";

type InvoicesListProps = {
  onChange: (key: string) => void;
};

const InvoicesList: React.FC<InvoicesListProps> = (props) => {
  const api = useAPI(ReportsApi);
  const { onChange } = props;
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setloadingDownload] = useState(false);

  const columns: ProColumns[] = [
    {
      title: "status",
      key: "reportResult",
      dataIndex: "reportResult",
      renderText: (value: string) => (
        <>
          <TagInvoice value={value} />
        </>
      ),
    },
    {
      title: "NF",
      key: "nfe",
      dataIndex: "nfe",
    },
    {
      title: "cpf/cnpj",
      key: "cpu",
      dataIndex: "identifierNumber",
    },
    {
      title: "Lote",
      key: "batch",
      dataIndex: "batch",
    },
    {
      title: "Qtd Faturamento",
      key: "disk",
      dataIndex: "invoicesTotalQty",
    },
    {
      title: "Qtd ITS",
      key: "royaltiesTotalQty",
      dataIndex: "royaltiesTotalQty",
    },
    {
      hideInTable: true,
      key: "keyIntegration",
      dataIndex: "keyIntegration",
    },
  ];
  return (
    <ProTable
      loading={loading}
      columns={columns}
      request={(params, sorter, filter) => {
        setLoading(true);
        return api
          .reportsControllerGetNotes({
            page: params?.current,
            pageSize: params?.pageSize,
          })
          .then((data) => {
            return {
              data: data.list,
              success: true,
              total: data.total,
            };
          })
          .finally(() => setLoading(false));
      }}
      rowKey="keyIntegration"
      toolbar={{
        actions: [
          <Button
            icon={<CloudDownloadOutlined />}
            loading={loadingDownload}
            onClick={async () => {
              setloadingDownload(true);
              const response = await api.reportsControllerExportITSNotes();
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(response);
              link.download = "data.xlsx"; // Nome do arquivo que será baixado
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setloadingDownload(false);
            }}
          >
            Exportar lista
          </Button>,
        ],
      }}
      options={false}
      pagination={{
        pageSize: 12,
        showSizeChanger: false,
      }}
      scroll={{ x: 600 }}
      search={false}
      onRow={(record) => {
        return {
          onClick: () => {
            if (record.keyIntegration) {
              onChange(record.keyIntegration);
            }
          },
        };
      }}
    />
  );
};

const InvoiceRecords: React.FC = () => {
  const [keyIntegration, setKeyIntegration] = useState<string>();
  return (
    <ProCard split="vertical" style={{ marginTop: 15 }}>
      <ProCard colSpan="40%" ghost>
        <InvoicesList
          onChange={(keyIntegration) => {
            setKeyIntegration(keyIntegration);
          }}
        />
      </ProCard>

      <ProCard colSpan="60%">
        {keyIntegration ? (
          <InvoicesRecords keyIntegration={keyIntegration} />
        ) : (
          <Empty />
        )}
      </ProCard>
    </ProCard>
  );
};

export default InvoiceRecords;
